<template>
  <div class="restaurantCard" :style="{ backgroundImage: 'url(' +  require(`../assets/restaurants/`+resto.imagen) + ')'}" @click="openModal()">
    <div class="card-text">
      <p class="nombre-resto">{{ resto.nombre }}</p>
      <p class="categoria-resto">{{ resto.categoria }}</p>
    </div>
  </div>
</template>

<script>
import RestaurantDetail from "./RestaurantDetail";
export default {
  name: "RestaurantCard",
   
  props: {
    resto: { type: Object, required: true },
  },
  methods: {
    openModal() {
      console.log("abrireModal")
      this.$parent.$emit('restoPosition')
      console.log(document.body);
      console.log(document.getElementById("restaurants-list"));
      var element = document.getElementById("restaurants-list");
      element.classList.add("open-modal")
      this.$buefy.modal.open({
        component: RestaurantDetail,
        fullScreen:true,
        active: true,
        parent: this,
        props: {
          resto: this.resto,
        },
        customClass:"RestaurantDetail", 
        
        
      });
    },
  },
};
</script>
