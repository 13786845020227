<template>
  <div class="restaurants" id="restaurants-list">
    <p class="restaurants-p">Locales Checkin</p>
    <div class="restaurants-list" >
      <RestaurantCard v-for="r in checkInR" :key="r.id" :resto="r" >
      </RestaurantCard>
    </div>
    <p class="restaurants-p">Todos los locales</p>
    <div class="restaurants-list" >
      <RestaurantCard v-for="r in restaurantes" :key="r.id" :resto="r">
      </RestaurantCard>
    </div>
  </div>
</template>

<script>
import restos from "./restos.json";
import RestaurantCard from "./RestaurantCard.vue";

export default {
  name: "RestaurantsList",
  components: { RestaurantCard },
  data() {
    return {
      restaurantes: [],
    };
  },
  mounted() {
    this.restaurantes = restos.restos;
  },
  computed: {
    checkInR() {
      return this.restaurantes.filter((res) => res.app);
    },
  },
};
</script>
