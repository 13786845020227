<template>
  <div class="home">
    <div class="headerDetail">
      <div class="boxDetail">
        <div class="location">
          <img src="../assets/location2.svg" class="locationLogo" />
          <h3 class="locationText">Pucón, Región de la Araucanía</h3>
        </div>
      </div>
    </div>
    <img src="../assets/ToteatN.svg" class="toteatLogoN" />
    <div @click="closeModal()" class="closeModal">
      < Volver a todos los locales
    </div>
    <div class="modalDetail">
      <div class="restaurantDetail" >
        <div class="restaurantCard">
          <div :id="resto.id" class="restaurantDetailImage" :style="{ backgroundImage: 'url(' +  require(`../assets/restaurants/`+resto.imagen) + ')'}"></div>
          <div class="detailText">
            <p class="puntosDetail">. . . .</p>
            <h1 class="restoName">{{ resto.nombre }}</h1>
            <p class="infoDetail">{{ resto.url }}</p>
            <p class="infoDetail">{{ resto.direccion }}</p>
            <p class="infoDetail">{{ resto.region }}</p>
            <p class="infoDetail">{{ resto.telefono }}</p>
            <p class="infoDetail">{{ resto.categoria }}</p>
          </div>
          <a :href="resto.carta" target="_blank" class="cartaH"> <div class="cartaButton" >Ver Carta</div></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestaurantDetail",
  
  props: {
    resto: { type: Object, required: true },
  },
  computed: {
    getLogo() {
      return "../assets/restautants/" + this.resto.logo;
    },
  },
  methods: {
    closeModal() {
      var element = document.getElementById("restaurants-list");
      element.classList.remove("open-modal");

      this.$parent.close();
      console.log("scroleareee")
      this.$parent.$parent.$parent.$emit('setPosition')
    },
  },
};
</script>
