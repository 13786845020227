<template>
  <div class="home" id="home">
    <div class="header">
      <div class="box">
        <div class="location">
          <img src="../assets/location2.svg" class="locationLogo" />
          <h3 class="locationText">Pucón, Región de la Araucanía</h3>
        </div>
        <img src="../assets/Toteat.svg" class="toteatLogo" />
        <div class="content">
          Encuentra la mejor experiencia de la gastronomía local
        </div>
      </div>
    </div>
    <div class="checkIn">
      <div class="text">
        <h4 @click="posicionY" class="title">Locales con Check in</h4>
        <p class="ccontent">conoce la vanguardia</p>
        <p class="ccontent">en Autoatención</p>
      </div>
      <div class="checkInImage"></div>
    </div>
    <RestaurantsList @restoPosition="posicionY" @setPosition="sPos"></RestaurantsList>
  </div>
</template>

<script>
import RestaurantsList from "./../components/RestaurantsList.vue";

export default {
  name: "Home",
  components: { RestaurantsList },
  data() {
    return {
      position: 0,
    };
  },

  methods: {
    posicionY() {
      console.log('llegando aca posicionY')
      this.position = window.scrollY;
    },
    sPos(){
      console.log("seteando pos")
      window.scrollTo(0, this.position)
    }
  },
};
</script>
